// auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // this.authService.check();
    if (this.authService.check()) {
      console.log('auth.guard.ts');
      return true;
    } else {
      console.log('auth.guard.ts');
      console.log('this.authService.redirectUrl' , state.url );
      // Store the attempted URL for redirection after login
      this.authService.redirectUrl = state.url;
      // Redirect to login page
      localStorage.clear();
      return this.router.parseUrl('/pages/login');
    }
  }
}
