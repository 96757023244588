import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  redirectUrl: any;

  constructor( private router: Router) { 
    this.check()
  }

  check(): boolean {
    const token = localStorage.getItem('authtoken');
    return !!token; // Convert token existence to boolean
  }
  

  logout(){
    localStorage.clear();
    this.isLoggedIn = false;
    this.router.navigate(['/pages/login']);
  }
}
