import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { HROUTES } from '../horizontal-menu/navigation-routes.config';
import { RouteInfo } from './vertical-menu.metadata';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  public PhysicianRoutes: RouteInfo[] = [
    { path: '/dashboard/physician', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '/patient', title: 'Patient', icon: 'ft-user', class: '', isExternalLink: false, submenu: []
    },
    { 
      path: '', title: 'Manage Appointment', icon: 'icon-calendar', class: 'has-sub', isExternalLink: false, submenu: [
        { path: '/schedular/view', title: 'Schedular', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/schedular/appointment', title: 'Appointments List', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/schedular/availability', title: 'Availablity', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
      ]  
    },
    { 
      path: '', title: 'Reports', icon: 'ft-file', class: 'has-sub', isExternalLink: false, submenu: [
        { path: '/report/notes', title: 'Notes', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/report/appointments', title: 'Appointments', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/report/surveys', title: 'Surveys', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
      ] 
    },    
    { 
      path: '/billing', title: 'Billing', icon: 'icon-wallet', class: '', isExternalLink: false, submenu: [] 
    },
    { 
      path: '/apps/chat', title: 'Chat', icon: 'ft-message-square', class: '', isExternalLink: false, submenu: [] 
    },
    { 
      path: '/ePrescription', title: 'ePrescription', icon: 'ft-file-plus', class: '', isExternalLink: false, submenu: [] 
    },
    // { 
    //   path: '/community', title: 'Community', icon: 'ft-users', class: '', isExternalLink: false, submenu: [] 
    // },
    { 
      path: '/setting', title: 'Setting', icon: 'ft-settings', class: 'has-sub', isExternalLink: false, submenu: [
        { path: '/setting/account', title: 'My Account', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/setting/practice', title: 'Practice Detail', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/setting/location', title: 'Location', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/setting/calendar', title: 'Calendar', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/setting/team', title: 'Team Management', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/setting/intake-form', title: 'Intake Form', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/setting/email-template', title: 'Email Template', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/setting/survey', title: 'Surveys', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        {
          path: '', title: 'Billing', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', isExternalLink: false,
          submenu: [
            { path: '/setting/billing-template', title: 'Billing Template', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
            { path: '/setting/custom-codes', title: 'Custom Billing Code', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
            { path: '/setting/payment-processing', title: 'Payment Processing', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
          ]
        },
      ] 
    },
  ]

  public PatientRoutes: RouteInfo[] = [
    { 
      path: '/dashboard/patient', title: 'Dashboard', icon: 'ft-home', class: '',   badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
    },
    { 
      path: '/document', title: 'Document', icon: 'ft-home', class: '',   badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
    },
    { 
      path: '', title: 'Mange Appointments', icon: 'ft-calendar', class: 'has-sub', isExternalLink: false, submenu: [
        { path: '/appointments/record', title: 'Appointments & Records', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/appointments/booking', title: 'Book Appointment', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] }
      ] 
    },
    { 
      path: '/apps/chat', title: 'Chat', icon: 'ft-message-square', class: '', isExternalLink: false, submenu: [] 
    },
    { 
      path: '/homekit', title: 'HomeKit', icon: 'icon-drop', class: 'has-sub', isExternalLink: false, submenu: [
        { path: '/homekit/ordered', title: 'My Kits', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/homekit/wishlist', title: 'My Wishlist', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/homekit/product', title: 'Injection Kits', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
      ] 
    },
    { 
      path: '/billing/view', title: 'Payments & Billing', icon: 'fa fa-credit-card', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
    },
    { 
      path: '/homekit/cart', title: 'Cart', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
    },
    { 
      path: '/apps/hexus-ai', title: 'Hexus AI', icon: 'fa fa-user-md', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
    },
    { 
      path: '/settings', title: 'Setting', icon: 'ft-settings', class: 'has-sub', isExternalLink: false, submenu: [
        { path: '/settings/profile', title: 'My Account', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: '/settings/payment', title: 'Payment Management', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
      ] 
    },
    // { 
    //   path: '/payment', title: 'Payment Management', icon: 'ft-credit-card', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
    // },
    // { 
    //   path: '/profile', title: 'Profile', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
    // },
  ];

  public AdminRoutes: RouteInfo[] = [
    { 
      path: '/dashboard/clinic-admin', title: 'Dashboard', icon: 'ft-home', class: '',   badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
    },
    { 
      path: '', title: 'User Management', icon: 'ft-users', class: 'has-sub', isExternalLink: false, submenu: [
        { path: '/user/provider', title: 'Providers', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        {  path: '/user/patient', title: 'Patients', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      ] 
    },
    { 
      path: '/earning/view', title: 'Earning', icon: 'ft-dollar-sign', class: '',   badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
    },
    { 
      path: '/kits/view', title: 'Infusion Kits', icon: 'icon-drop', class: '',   badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
    },
    { 
      path: '/orders/manage', title: 'Manage Orders', icon: 'icon-drop', class: '',   badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
    },
  ];

  public GenaralRoutes: RouteInfo[] = [
    {
      path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '2', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
        { path: '/dashboard/physician', title: 'Dashboard 1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/dashboard/clinic-admin', title: 'Dashboard 2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
  ];

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  public showBarRoutes: any[];
  public showAwailableRoutes: any[];
  userRole = JSON.parse(localStorage.getItem('user')).roleName;
  level: number = 0;
  logoUrl = 'assets/img/therafusion.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
    if(this.userRole === 'Physician'){
      console.log('Is Physician');
      this.showBarRoutes = this.PhysicianRoutes;
    }else if(this.userRole === 'Patient'){
      console.log('Is Patient');
      this.showBarRoutes = this.PatientRoutes;
    }else if(this.userRole === 'Clinic Admin'){
      console.log('Is Clinic Admin');
      this.showBarRoutes = this.AdminRoutes;
    }else if(this.userRole === 'General'){
      console.log('Is General');
      this.showBarRoutes = this.GenaralRoutes;
    }
    this.showAwailableRoutes = this.showBarRoutes;
  }

  ngOnInit() {
    this.menuItems = this.showAwailableRoutes;
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
          this.loadLayout();
      }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = this.showAwailableRoutes;
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      this.menuItems = this.showAwailableRoutes;
    }

    if(this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
