import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable()

export class TimezoneService {

  setESTTimezone() {
    moment.tz.setDefault('America/New_York');
  }
  
}
