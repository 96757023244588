import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appTimeZoneConversion]'
})
export class TimeZoneConversionDirective {

  @HostListener('change', ['$event']) onChange(event: any) {
    const selectedDate = new Date(event.target.value + 'T00:00:00');
    const estOffset = -5 * 60; // UTC offset for EST is -5 hours
    const estTime = new Date(selectedDate.getTime() + estOffset * 60 * 1000); // Convert to EST
    const estDateString = estTime.toISOString().split('T')[0]; // Get the date in EST format
    event.target.value = estDateString; // Set the input value to the EST date
  }
}
