import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'schedular',
    loadChildren: () => import('../../TheraFusion/schedular/schedular.module').then(m => m.SchedularModule)
  },
  {
    path: 'apps',
    loadChildren: () => import('../../TheraFusion/apps/apps.module').then(m => m.AppsModule)
  },
  // {
  //   path: 'appointment',
  //   loadChildren: () => import('../../TheraFusion/appointment/appointment.module').then(m => m.AppointmentModule)
  // },
  {
    path: 'report',
    loadChildren: () => import('../../TheraFusion/report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('../../TheraFusion/billing/billing.module').then(m => m.BillingModule)
  },
  {
    path: 'patient',
    loadChildren: () => import('../../TheraFusion/patient/patient.module').then(m => m.PatientModule)
  },
  {
    path: 'invoice',
    loadChildren: () => import('../../TheraFusion/invoice/invoice.module').then(m => m.InvoiceModule)
  },
  {
    path: 'ePrescription',
    loadChildren: () => import('../../TheraFusion/eprescription/eprescription.module').then(m => m.EprescriptionModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('../../TheraFusion/setting/setting.module').then(m => m.SettingModule)
  },
  // {
  //   path: 'chat',
  //   loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
  // },
  {
    path: 'talk',
    loadChildren: () => import('../../TheraFusion/talk-js/talk-js.module').then(m => m.TalkJsModule)
  },

  // Clinic Admin End

  // Patient

  // {
  //   path: 'profile',
  //   loadChildren: () => import('../../TheraFusion-Patient/profile/profile.module').then(m => m.ProfileModule)
  // },
  // {
  //   path: 'payment',
  //   loadChildren: () => import('../../TheraFusion-Patient/payment-management/payment-management.module').then(m => m.PaymentManagementModule)
  // },
  {
    path: 'document',
    loadChildren: () => import('../../TheraFusion-Patient/document/document.module').then(m => m.DocumentModule)
  },
  {
    path: 'homekit',
    loadChildren: () => import('../../TheraFusion-Patient/homekit/homekit.module').then(m => m.HomekitModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('../../TheraFusion-Patient/patient-payment-billing/patient-payment-billing.module').then(m => m.PatientPaymentBillingModule)
  },
  {
    path: 'appointments',
    loadChildren: () => import('../../TheraFusion-Patient/patient-appointment/patient-appointment.module').then(m => m.PatientAppointmentModule)
  },
  {
    path: 'doctor',
    loadChildren: () => import('../../TheraFusion-Patient/doctor/doctor.module').then(m => m.DoctorModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../TheraFusion-Patient/patient-setting/patient-setting.module').then(m => m.PatientSettingModule)
  },

  // Admin 
  {
    path: 'user',
    loadChildren: () => import('../../TheraFusion-Admin/user-management/user-management.module').then(m => m.UserManagementModule)
  },
  {
    path: 'earning',
    loadChildren: () => import('../../TheraFusion-Admin/earning/earning.module').then(m => m.EarningModule)
  },
  {
    path: 'kits',
    loadChildren: () => import('../../TheraFusion-Admin/infusion-kits/infusion-kits.module').then(m => m.InfusionKitsModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('../../TheraFusion-Admin/orders/orders.module').then(m => m.OrdersModule)
  },
  // End 

  // General
  {
    path: 'calendar',
    loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)
  },
  {
    path: 'charts',
    loadChildren: () => import('../../charts/charts.module').then(m => m.ChartsNg2Module)
  },
   {
    path: 'forms',
    loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule)
  },
  {
    path: 'tables',
    loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule)
  },
  {
    path: 'datatables',
    loadChildren: () => import('../../data-tables/data-tables.module').then(m => m.DataTablesModule)
  },
  {
    path: 'uikit',
    loadChildren: () => import('../../ui-kit/ui-kit.module').then(m => m.UIKitModule)
  },
  {
    path: 'components',
    loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('../../cards/cards.module').then(m => m.CardsModule)
  },
  // {
  //   path: 'chat',
  //   loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
  // },
  {
    path: 'chat-ngrx',
    loadChildren: () => import('../../chat-ngrx/chat-ngrx.module').then(m => m.ChatNGRXModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)
  },
  {
    path: 'taskboard',
    loadChildren: () => import('../../taskboard/taskboard.module').then(m => m.TaskboardModule)
  },
  {
    path: 'taskboard-ngrx',
    loadChildren: () => import('../../taskboard-ngrx/taskboard-ngrx.module').then(m => m.TaskboardNGRXModule)
  }
];
