import { Component, OnInit , Input , ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-small-detail-view',
  templateUrl: './small-detail-view.component.html',
  styleUrls: ['./small-detail-view.component.scss']
})
export class SmallDetailViewComponent implements OnInit {

  @Input() name: string;
  @Input() data: any;

  constructor(
    public activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
  }

}
