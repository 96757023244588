<div class="modal-header">
    <h5 class="modal-title">{{name}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
      <div class="col-12 mb-2" *ngFor="let item of data; let i = index">
        <h6 class="text-gray-700">{{item.title}} :</h6>
        <p class="ml-1 mb-2">
          {{item.data}}
        </p>
      </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Close</button>
</div>