<!--Footer Starts-->
<footer class="footer text-center">
  <p class="copyright clearfix text-muted m-0">
    Powered by <a class="text-bold-600" href="javascript::">Quantumz</a>
  </p>
  <!-- <p class="copyright clearfix text-muted m-0">
    Copyright &copy; {{currentDate | date: 'yyyy'}} <a id="TherafusionLink"
      href="https://therafusion.com/contact-us/">Therafusion Inc</a>
    <span class="d-none d-sm-inline-block">, All rights reserved.</span>
  </p> -->
</footer>
<!--Footer Ends-->
